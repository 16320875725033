<template>
  <div id="app">
    <v-app class="transparent_bg">
      <div class="list-table">
        <v-container grid-list-xl>
          <v-snackbar v-model="showSnackbar" :timeout="5000">
            {{ snackbarText }}
            <v-btn color="#673ab7" text @click="showSnackbar = false">
              Fechar
            </v-btn>
          </v-snackbar>

          <v-layout row wrap>
            <v-flex lg12>
              <v-card>
                <div class="layout column align-center">
                  <h3>{{ currentProject.name }}</h3>
                  <h3>{{ currentProject.client }}</h3>
                </div>

                <div class="layout column align-center">
                  <ShipVolumeBar
                    :volume-to-ship="currentVolumeId"
                    v-if="!isAllOnBoard"
                  />
                  <v-btn
                    v-else
                    color="primary"
                    small
                    :loading="loading"
                    class="mt-3 mb-3"
                    @click="_deliverAndNavigateToProjects"
                  >
                    Projeto saiu para montagem
                  </v-btn>
                </div>

                <v-divider></v-divider>

                <v-toolbar card color="white">
                  <v-text-field
                    flat
                    solo
                    v-model="search"
                    prepend-icon="search"
                    placeholder="Filtrar"
                    hide-details
                    class="hidden-sm-and-down"
                  ></v-text-field>
                </v-toolbar>
                <v-divider></v-divider>
                <v-card-text class="pa-0">
                  <v-data-table
                    :headers="complex.headers"
                    :search="search"
                    :items="complex.items"
                    pagination.sync="query"
                    :rows-per-page-items="[1000, 2000, 5000]"
                    class="elevation-0"
                    item-key="_id"
                  >
                    <template
                      slot="items"
                      slot-scope="props"
                      class="justify-center"
                    >
                      <td>{{ props.item.name }}</td>
                      <td>{{ props.item.pieces.length }}</td>
                      <td>{{ props.item.project.name }}</td>
                      <td>{{ props.item.project.ambient }}</td>
                      <td>
                        <Tag :status="props.item.status" />
                      </td>
                      <td>
                        <v-tooltip bottom v-if="!_isOnBoard(props.item)">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              dark
                              color="red"
                              medium
                              class="right"
                              v-on="on"
                              @click="_setCurrentVolume(props.item)"
                            >
                              <v-icon>local_shipping</v-icon>
                            </v-btn>
                          </template>
                          <span>Embarcar Volume</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              dark
                              color="red"
                              medium
                              class="right"
                              v-on="on"
                              @click="_printTag(props.item)"
                            >
                              <v-icon>print</v-icon>
                            </v-btn>
                          </template>
                          <span>Imprimir etiqueta</span>
                        </v-tooltip>
                      </td>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </v-app>

    <printable-tag-dialog
      v-if="currentVolume"
      :show="showPrintDialog"
      :volume="currentVolume"
      :dismiss-action="() => (this.showPrintDialog = false)"
    ></printable-tag-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import constants from '@/utils/constants';
import Tag from '@/components/project/Tag';
import PrintableTagDialog from '@/components/dialogs/PrintableTagDialog';
import ShipVolumeBar from '@/components/project/ShipVolumeBar';

export default {
  components: { ShipVolumeBar, PrintableTagDialog, Tag },
  data() {
    return {
      loading: false,
      showSnackbar: false,
      showPrintDialog: false,
      snackbarText: '',
      currentUser: null,
      projects: [],
      currentProject: [],
      query: {
        descending: false,
        page: 1,
        rowsPerPage: 10,
        sortBy: 'name',
        totalItems: 0
      },
      search: '',
      currentVolume: null,
      currentVolumeId: null,
      complex: {
        headers: [
          {
            text: 'Nome',
            value: 'name'
          },
          {
            text: 'Peças',
            value: 'Peças'
          },
          {
            text: 'Project',
            value: (v) => {
              return v.project.name;
            }
          },
          {
            text: 'Ambient',
            value: (v) => {
              return v.project.ambient;
            }
          },
          {
            text: 'Status',
            value: 'status'
          },
          {
            text: '',
            value: '',
            sortable: false
          }
        ],
        items: []
      }
    };
  },
  computed: {
    ...mapGetters(['getVolumes', 'getProjects', 'getUser']),
    ...mapState({
      volumes: (state) => state.volume.volumes
    }),
    isAllOnBoard() {
      return (
        this.volumes.filter(
          (volume) => volume.status !== constants.STATUS_ON_BOARD
        ).length === 0
      );
    }
  },

  created() {
    this.currentUser = this.getUser;
    this.projects = this.getProjects;
    this.currentProject = this._getCurrentProject();
  },

  async mounted() {
    this.currentUser = this.getUser;
    try {
      await this._listVolumes(this.$route.params.id);
    } catch (e) {
      console.log(e);
    }

    console.log(this.volumes);
    this.complex.items = this.volumes;
  },
  methods: {
    ...mapActions({
      _listVolumes: 'listVolumes',
      _deliverProject: 'deliverProject'
    }),

    _getCurrentProject() {
      const filtered = this.projects.filter(
        (project) => project._id === this.$route.params.id
      );
      return filtered[0] ? filtered[0] : {};
    },
    _setCurrentVolume(volume) {
      this.currentVolume = volume;
      this.currentVolumeId = volume['volumeId'];
      console.log(this.currentVolumeId);
    },
    _isOnBoard(volume) {
      return volume.status === constants.STATUS_ON_BOARD;
    },
    _printTag(volume) {
      this.currentVolume = volume;
      this.showPrintDialog = true;
    },
    async _deliverAndNavigateToProjects() {
      try {
        if (this.currentProject.status === constants.STATUS_READY_FOR_PACKAGE) {
          await this._deliverProject(this.$route.params.id);
        }
        await this.$router.push(`/`);
      } catch (e) {
        console.log(e);
      }
    }
  },
  watch: {
    volumes(newValue) {
      this.complex.items = newValue;
    }
  }
};
</script>

<style lang="css" scoped></style>
